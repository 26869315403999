// LinkModal.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import apiClient from './apiClient';

let baseUrl = "/api";//"http://localhost:5001";//

const LinkModal = ({ link, entities, setLinks, setShowLinkModal, fetchLinks }) => {
    const [formData, setFormData] = useState({
        title: link ? link.title : '',
        selectedEntities: link ? link.entities : [],
        sureviewPlatformLinks: link ? link.sureviewPlatformLinks || [] : []
    });
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState('');

    const entityOptions = entities.map(entity => ({
        value: entity.id,
        label: `${entity.name} (${entity.platform})`,
        entity
    }));

    const platformOptions = [
        { value: 'Response On-Prem', label: 'Response On-Prem' },
        { value: 'Response SaaS', label: 'Response SaaS' },
        { value: 'Suite', label: 'Suite' },
        { value: 'Cases', label: 'Cases' }
    ];

    useEffect(() => {
        if (!formData.title && formData.selectedEntities.length === 1) {
            setFormData(prev => ({ ...prev, title: formData.selectedEntities[0].entity.name }));
        }
    }, [formData.selectedEntities, formData.title]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handlePlatformLinkChange = (index, field, value) => {
        const updatedLinks = [...formData.sureviewPlatformLinks];
        updatedLinks[index][field] = value;
        setFormData(prev => ({ ...prev, sureviewPlatformLinks: updatedLinks }));
    };

    const addPlatformLink = () => {
        setFormData(prev => ({
            ...prev,
            sureviewPlatformLinks: [...prev.sureviewPlatformLinks, { platform: '', region: '', identifier: '' }]
        }));
    };

    const removePlatformLink = (index) => {
        const updatedLinks = formData.sureviewPlatformLinks.filter((_, i) => i !== index);
        setFormData(prev => ({ ...prev, sureviewPlatformLinks: updatedLinks }));
    };

    const saveLink = async () => {
        try {
            const payload = {
                title: formData.title,
                entities: formData.selectedEntities,
                sureviewPlatformLinks: formData.sureviewPlatformLinks
            };
            const response = link
                ? await apiClient.put(`${baseUrl}/links/${link.id}`, payload)
                : await apiClient.post(`${baseUrl}/links`, payload);
            setLinks(prevLinks => [...prevLinks.filter(l => l.id !== response.data.id), response.data]);
        } catch (error) {
            console.error("There was an error saving the link!", error);
        }
        setShowLinkModal(false);
        fetchLinks();
    };

    const deleteLink = async () => {
        if (confirmDelete === 'DELETE' && link) {
            try {
                await apiClient.delete(`${baseUrl}/links/${link.id}`);
                setLinks(prevLinks => prevLinks.filter(l => l.id !== link.id));
            } catch (error) {
                console.error("There was an error deleting the link!", error);
            }
            setShowLinkModal(false);
            setShowDeleteConfirm(false);
            setConfirmDelete('');
        }
    };

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-60">
            {showDeleteConfirm && (
                <div className="fixed z-20 inset-0 overflow-y-auto bg-black bg-opacity-60">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
                        <div className="inline-block align-bottom bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
                            <div className="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg leading-6 font-medium text-white" id="modal-title">
                                            Confirm Delete
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-400">
                                                Are you sure you want to delete this link? This action cannot be undone.
                                                Type "DELETE" to confirm.
                                            </p>
                                            <input
                                                type="text"
                                                onChange={(e) => setConfirmDelete(e.target.value)}
                                                className="bg-gray-800 text-white p-2 my-4 rounded border w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    onClick={deleteLink}
                                    type="button"
                                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm 
                            ${confirmDelete === 'DELETE'
                                            ? 'bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                                            : 'bg-gray-600 text-gray-400 cursor-not-allowed hover:bg-gray-600'}`}
                                    disabled={confirmDelete !== 'DELETE'}
                                >
                                    Confirm Delete
                                </button>
                                <button
                                    onClick={() => setShowDeleteConfirm(false)}
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>


                    </div>
            )}
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
                <div className="inline-block align-middle bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-lg">
                    <div className="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-300 mb-1">Title</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder="Title"
                                className="bg-gray-800 text-white p-2 rounded border w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-300 mb-1">Entities</label>
                            <Select
                                isMulti
                                options={entityOptions}
                                value={formData.selectedEntities}
                                onChange={(selected) => setFormData(prev => ({ ...prev, selectedEntities: selected }))}
                                className="text-black"
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({
                                        ...base,
                                        zIndex: 9999
                                    })
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-300 mb-1">SureView Platform Links</label>
                            {formData.sureviewPlatformLinks.map((link, index) => (
                                <div key={index} className="flex items-center mb-2">
                                    <Select
                                        options={platformOptions}
                                        value={platformOptions.find(option => option.value === link.platform)}
                                        onChange={(selected) => handlePlatformLinkChange(index, 'platform', selected.value)}
                                        className="text-black w-1/3 mr-2"
                                    />
                                    <input
                                        type="text"
                                        value={link.region}
                                        onChange={(e) => handlePlatformLinkChange(index, 'region', e.target.value)}
                                        placeholder="Region"
                                        className="bg-gray-800 text-white p-2 rounded border w-1/3 mr-2"
                                    />
                                    <input
                                        type="text"
                                        value={link.identifier}
                                        onChange={(e) => handlePlatformLinkChange(index, 'identifier', e.target.value)}
                                        placeholder="Identifier"
                                        className="bg-gray-800 text-white p-2 rounded border w-1/3 mr-2"
                                    />
                                    <button
                                        onClick={() => removePlatformLink(index)}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        X
                                    </button>
                                </div>
                            ))}
                            <button
                                onClick={addPlatformLink}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
                            >
                                Add Platform Link
                            </button>
                        </div>
                    </div>

                    <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            onClick={saveLink}
                            type="button"
                            disabled={!formData.title.trim() || formData.selectedEntities.length === 0}
                            className={`w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium 
                                ${(!formData.title.trim() || formData.selectedEntities.length === 0)
                                    ? 'bg-gray-500 cursor-not-allowed hover:bg-gray-500'
                                    : 'bg-green-500 hover:bg-green-700 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'} 
                                sm:ml-3 sm:w-auto sm:text-sm`}
                        >
                            Save
                        </button>

                        {link && (
                            <button onClick={() => setShowDeleteConfirm(true)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                Delete
                            </button>
                        )}
                        <button onClick={() => setShowLinkModal(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkModal;