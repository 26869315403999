import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import LinkModal from './LinkModal';
import 'tailwindcss/tailwind.css';
import './App.css';
import { AiOutlinePlus } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import apiClient from './apiClient'; // Import apiClient directly
import { jwtDecode } from 'jwt-decode';

let baseUrl = "/api";//"http://localhost:5001";//

function Pmo() {
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [currentLink, setCurrentLink] = useState(null);
  const [linkSearchTerm, setLinkSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    let isTokenExpired = true;
  
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // in seconds
        isTokenExpired = decodedToken.exp < currentTime;
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  
    if (!token || isTokenExpired) {
      navigate('/');
    } else {
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchLinks();
    }
  }, [navigate]); 
  

  const handleLogout = () => {
    localStorage.removeItem('token');
    if (apiClient.defaults.headers.common['Authorization']) {
      delete apiClient.defaults.headers.common['Authorization'];
    }
    navigate('/');
  };

  const openLinkModal = (link = null) => {
    setCurrentLink(link);
    setShowLinkModal(true);
  };

  const fetchLinks = useCallback(async () => {
    try {
      const response = await apiClient.get(`${baseUrl}/links`);
      setLinks(response.data);
    } catch (error) {
      console.error("There was an error fetching the links!", error);
    }
  }, [baseUrl]); // Include all dependencies here  

  const fetchEntities = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.get(`${baseUrl}/entities`);
      setEntities(
        Object.keys(response.data)
          .map(key =>
            response.data[key].map(entity => ({ ...entity, platform: key }))
          )
          .flat()
      );
    } catch (error) {
      console.error("There was an error fetching the entities!", error);
    }
    setIsLoading(false);
  };

  const openModal = (entity) => {
    setModalType('edit');
    setSelectedEntity(entity);
    setShowModal(true);
  };

  const addEntity = (platform) => {
    setSelectedEntity({ platform });
    setModalType('add');
    setShowModal(true);
  };

  return (
    <div className="App bg-gray-900 text-white min-h-screen p-8">
      <div className="mb-6">
        <h1 className="text-5xl font-bold text-center">SureView</h1>
      </div>

      <div className="flex">
        <div className="w-1/6 pr-8 flex flex-col items-start">
          <button
            onClick={() => openLinkModal()}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-2 flex items-center w-full ml-2"
          >
            <AiOutlinePlus className="mr-2" /> {"New Link"}
          </button>
          {['Clockify', 'Hubspot', 'Loop', 'ZenDesk', 'Asana', 'Float', 'Slack'].map(platform => (
            <button
              key={platform}
              onClick={() => addEntity(platform)}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-2 flex items-center w-full"
            >
              <AiOutlinePlus className="mr-2" /> {platform}
            </button>
          ))}
          <button onClick={handleLogout} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-2 flex items-center w-full">
            Logout
          </button>
        </div>

        <div className="w-5/6 flex flex-col h-[500px]">
        <h2 className="text-3xl font-bold mb-4 w-fit ml-2">Linked Entities</h2>
        <input
          type="text"
          placeholder="Search Entities"
          onChange={(e) => setLinkSearchTerm(e.target.value)}
          className="p-2 border rounded text-black w-full mb-4"
        />

        <div className='max-h-[400px] overflow-scroll'>
          {links
            .filter(link =>
              link.title.toLowerCase().includes(linkSearchTerm.toLowerCase()) ||
              link.entities.some(entity =>
                entity.entity.name.toLowerCase().includes(linkSearchTerm.toLowerCase()) ||
                entity.entity.platform.toLowerCase().includes(linkSearchTerm.toLowerCase())
              ) ||
              (link.sureviewPlatformLinks && link.sureviewPlatformLinks.some(platformLink =>
                platformLink.platform.toLowerCase().includes(linkSearchTerm.toLowerCase()) ||
                platformLink.region.toLowerCase().includes(linkSearchTerm.toLowerCase()) ||
                platformLink.identifier.toLowerCase().includes(linkSearchTerm.toLowerCase())
              ))
            )
            .map(link => (
              <div key={link.id} className="bg-gray-800 rounded p-4 mb-4 flex justify-between items-start">
                <div className="text-left w-full">
                  <h3 className="text-xl font-bold">{link.title}</h3>
                  <p className="text-sm italic text-gray-400 mb-2">
                    {link.entities.map(entity => `${entity.entity.name} (${entity.entity.platform})`).join(', ')}
                  </p>
                  {link.sureviewPlatformLinks && link.sureviewPlatformLinks.length > 0 && (
                    <div className="text-xs text-gray-300">
                      <p className="font-semibold mb-1">Platform Links:</p>
                      {link.sureviewPlatformLinks.map((platformLink, index) => (
                        <p key={index} className="ml-2">
                          {platformLink.platform}: {platformLink.region} - {platformLink.identifier}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => openLinkModal(link)}
                    className="text-white font-bold mt-2 py-2 px-4 rounded mr-2"
                    title="Edit"
                  >
                    <FaEdit />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-center mb-4">
          {isLoading ? (
            <button
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
              disabled
            >
              Loading...
            </button>
          ) : (
            <button
              style={{ whiteSpace: 'nowrap' }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={fetchEntities}
            >
              Load All Customers
            </button>
          )}

          {entities.length > 0 && (
            <input
              type="text"
              placeholder="Search customers by name or subsystem"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 border rounded text-black w-full ml-4"
            />
          )}
        </div>

        {/* Entity list */}
        <div className="bg-gray-800 rounded p-4 overflow-scroll mb-4  max-h-[500px]">
          {entities
            .filter(entity =>
              entity.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              entity.platform.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((entity, index) => {
              const isLinked = links.some(link =>
                link.entities.some(e => e.entity.id === entity.id)
              );

              return (
                <div
                  key={index}
                  onClick={() => openModal(entity)}
                  className="cursor-pointer p-2 hover:bg-gray-700 flex justify-between items-center"
                >
                  <span className="text-white flex items-center">
                    {entity.name}
                    {isLinked && <span className="ml-2 text-green-500 text-sm italic ">Linked</span>}
                  </span>
                  <span className="text-gray-400 text-sm italic ">({entity.platform})</span>
                </div>
              );
            })}

        </div>
      </div>
      {showLinkModal && (
        <LinkModal
          link={currentLink}
          entities={entities}
          setLinks={setLinks}
          setShowLinkModal={setShowLinkModal}
          fetchLinks={fetchLinks}
        />
      )}
      {showModal && (
        <Modal
          selectedEntity={selectedEntity}
          setShowModal={setShowModal}
          fetchEntities={fetchEntities}
          modalType={modalType}
        />
      )}
    </div>
  );
}

export default Pmo;